window._env_ = {
  CHECKOUT_PM_HOST: "https://api.dev.platform.pagopa.it/payment-manager",
  CHECKOUT_SURVEY_SHOW: "0",
  CHECKOUT_VERSION: "main:680873bbdf05c6f14518224c54c99f0b9910e855",
  CHECKOUT_GDI_CHECK_TIMEOUT: "20000",
  CHECKOUT_PAGOPA_APIM_HOST: "https://api.dev.platform.pagopa.it",
  CHECKOUT_API_PAYMENT_TRANSACTIONS_BASEPATH: "/api/checkout/payment-transactions/v1",
  CHECKOUT_DONATIONS_URL: "https://api.dev.platform.pagopa.it/donations/api/v1/availabledonations",
  CHECKOUT_PAGOPA_ASSETS_CDN: "https://assets.cdn.platform.pagopa.it/payment-methods",
  CHECKOUT_API_ECOMMERCE_BASEPATH_V2: "/ecommerce/checkout/v2",
  CHECKOUT_API_TIMEOUT: "10000",
  CHECKOUT_POLLING_ACTIVATION_ATTEMPTS: "16",
  CHECKOUT_API_PAYMENT_ACTIVATIONS_BASEPATH: "/checkout/payments/v1",
  CHECKOUT_NPG_SDK_URL: "https://stg-ta.nexigroup.com/monetaweb/resources/hfsdk.js",
  CHECKOUT_RECAPTCHA_SITE_KEY: "6LcUlzsmAAAAAPJJ7qwksUOTBE1yLiakJlon62d3",
  CHECKOUT_PAGOPA_LOGOS_CDN: "https://assets.cdn.io.italia.it/logos/abi",
  CHECKOUT_ECOMMERCE_HOST: "https://api.dev.platform.pagopa.it",
  CHECKOUT_PM_API_BASEPATH: "/pp-restapi/v4",
  CHECKOUT_API_ECOMMERCE_BASEPATH: "/ecommerce/checkout/v1",
  CHECKOUT_POLLING_ACTIVATION_INTERVAL: "6000",
  CHECKOUT_ENV: "DEV",
}
